<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar dense class="tc-title">
      <v-toolbar-title>Schedule Company Organization, Program & Year</v-toolbar-title>
    </v-toolbar>
    <v-card>
      <v-container fluid>
        

        <v-row >
          <v-col sm="2">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Company Name :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ selected_company }}</span
              >
            </span>
          </v-col>
           <v-col sm="2">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Academic Year :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ academicyear }}</span
              >
            </span>
          </v-col>
           <v-col sm="4">
            <span style="color: black; font-size: 12; font-weight: normal"
              >Company Code :
              <span style="color: blue; font-size: 16; font-weight: bold">
                {{ code }}</span
              >
            </span>
          </v-col>
          <v-col sm="4">
                <v-btn
                        color="blue" style="float:right"
                        dark
                        @click="exceldialog1 = !exceldialog1"
                        >Excel Import</v-btn
                      >
                       <v-dialog width="350" persistent v-model="exceldialog1">
                        <v-card>
                          <v-card-title class="c-title"
                            >Excel import</v-card-title
                          >
                          <v-card-text
                            ><br />
                            
                            <v-row justify="end">
                              <a :href="url1">
                                <v-icon
                                  large
                                  color="blue"
                                  class="icon-quick"
                                  title="Download"
                                >
                                  mdi-file-excel
                                </v-icon>
                              </a>
                            </v-row>
                            <v-file-input
                              v-model="excelfile1"
                              label="Import Excel"
                            ></v-file-input>
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="clear1()" text>cancel</v-btn>
                              <v-btn @click="importexcel()" dark color="blue"
                                >Upload</v-btn
                              >
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      
                      <v-dialog
                        width="1000"
                        persistent
                        v-model="excelerrordialog1"
                      >
                        <v-card>
                          <v-card-title class="c-title">
                            <v-row justify="space-between">
                              <b>Excel Error</b>
                              <v-btn icon dark @click="closeerrordialog1">
                                <v-icon color="black">mdi-close</v-icon>
                              </v-btn>
                            </v-row>
                          </v-card-title>
                          <v-card-text
                            ><br /><br />
                        
                            <table 
                            style="width:100%"
                            
                             v-if="
                                Array.isArray(notfoundstudent1) &&
                                notfoundstudent1.length
                              "
                              class="pr-5"
                              justify="center">
                            <thead>
                            <th style="font-size:35px;">Attribute</th>
                            <th style="font-size:35px;">Error</th>
                            </thead>
                            <tbody >
                              <tr class="ma-5" v-for="(item,index) in notfoundstudent1" :key=index>
                                  <td class="text-center"> {{item.Company}} </td>
                                   <td  class="text-center red--text"> {{item.error}} </td>
                              </tr>
                            </tbody>
                            </table>

                                                    
                          </v-card-text>
                          <v-card-actions>
                            <v-row class="pr-5" justify="end">
                              <v-btn @click="closeerrordialog1()" text
                                >Close</v-btn
                              >
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col sm="2" class="mt-2">
            <v-select
              autocomplete="off"
              placeholder="Select Organization"
              :items="orglist"
              @change="getprogramTypelistforadd()"
              v-model="selectedorg"
              item-text="name"
              item-value="id"
            ></v-select>
          </v-col>

           <v-col sm="3" class="mt-2">
                <v-select
                  autocomplete="off"
                  placeholder="Select Program Type"
                  :items="programtype_list"
                  @change="getprogramlistbyTypeforadd()"
                  v-model="selectedprgtype"
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
          <v-col sm="4">
            <v-autocomplete
              autocomplete="off"
              chips
              multiple
              placeholder="Select Program"
              :items="program_list"
              v-model="selectedprogramlist"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col sm="2">
            <v-autocomplete
              autocomplete="off"
              chips
              multiple
              placeholder="Select Year"
              :items="year_list"
              v-model="selectedyearlist"
              item-text="name"
              item-value="id"
            ></v-autocomplete>
          </v-col>
          <v-col class="mt-5" sm=1>
            <v-btn  color="primary" @click="savecompanyofferingprogram()"
              >add</v-btn
            ></v-col
          >
        </v-row>
      </v-container>
    </v-card>
   
    <v-card class="ma-2" v-if="programdata.length != 0">
      <v-row justify="end">
        <div style="width: 30%">
          <v-text-field
            class="pa-5"
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field></div
      ></v-row>

      <v-data-table
        v-if="programdata.length != 0"
        :headers="programheader"
        :items="programdata"
        class="elevation-1 nowrap"
        :search="search"
      >
        <template v-slot:item.delete="{ item }">
          <v-icon
            v-if="item"
            class="mr-2 red--text"
            @click="deletecompanyofferingprogram(item)"
          >
            mdi mdi-delete</v-icon
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import axios from "axios";
import readXlsxFile from "read-excel-file";
export default {
  data: () => ({
     excelfile1: null,
    exceldialog1: false, 
    url1: "",
    excelerrordialog1: false,
    notfoundstudent1: [],
    selected_company: null,
    items: "",
    code:"",
    academicyear:"",
    snackbar: "",
    snackbar_msg: "",
    color: "",
    search: "",
    dialog: false,
    selectedorg: "",
    selectedprogramlist: [],
    selectedyearlist : [],
    orglist: [],
    programtype_list:[],
    selectedprgtype:"",
    programdata: [],
    program_list: [],
    year_list: [],
    programheader: [
      {
        text: "Sr no",
        sortable: true,
        value: "srno",
      },
      {
        text: "Organization",
        sortable: true,
        value: "organization",
      },
      {
        text: "Program",
        sortable: true,
        value: "program",
      },
      {
        text: "Year",
        sortable: true,
        value: "year",
      },
      {
        text: "Delete",
        sortable: true,
        value: "delete",
      },
    ],
  }),

  methods: {
     importexcel() {
        var programlist=[];
        readXlsxFile(this.excelfile1).then((rows) => {
            //console.log(rows);
            var i;
            for (i = 1; i < rows.length; i++) {
              var temp={};
              temp['Company_Code']=rows[i][0];
              temp['Organization']=rows[i][1];
              temp['Program']=rows[i][2];
              temp['Year']=rows[i][3];
              programlist.push(temp);
            }          
            var params={"programlist":programlist};
            axios.post("/TPOCompanyScheduling/saveSchedProgramByexcel",params).then((res) => {
                if (res.data.msg == "200") {
                    //console.log(res.data.errorlist.length)
                    if(res.data.errorlist.length!=0){
                        this.excelerrordialog1=true;
                        this.notfoundstudent1=res.data.errorlist;
                    }else{
                      this.showSnackbar("green", "Success.."); 
                    }         
                } else {
                  this.showSnackbar("red", res.data.msg); 
                }
            });
        });
        this.exceldialog1=false;
    },

    clear1() {
      this.excelfile1 = null;
      this.exceldialog1 = false;
    },
    
    closeerrordialog1() {
      this.clear1();
      this.excelerrordialog1 = false;
    },

    deletecompanyofferingprogram(item) {
      var params = {
        companyofferingprogram: item.id,
      };
      axios
        .post("/TPOCompanyScheduling/deletecompanyofferingprogram", params)
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.dialog = false;
            this.showSnackbar("green", "delete successfully....");
            this.getofferinginfo();
          } else {
            this.showSnackbar("green", res.data.msg);
          }
        });
    },

    getprogramTypelistforadd(){
       this.programtype_list=[]
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
      };

      axios.post("/TPOCompanyScheduling/getprogramTypelistforadd", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.programtype_list = res.data.programtype_list;
          //console.log("this.programtype_list");
         //console.log(this.programtype_list);
        } else {}
      });
    },

getprogramlistbyTypeforadd(){
          this.program_list=[]
      this.year_list1 = []

      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
        programtype:this.selectedprgtype,
      };

      axios.post("/TPOCompanyScheduling/getprogramlistbyTypeforadd", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.program_list = res.data.programlist;
          this.year_list = res.data.yearlist;
          //console.log("program_list ");
          //console.log(this.program_list);
        //  this.year_list1 = res.data.yearlist;
          //console.log("yearlist  1111 ");
          //console.log(this.year_list1);
        } else {}
      });

},





    savecompanyofferingprogram() {
      if(this.selectedorg==''){
         this.showSnackbar("red","please select organization");
      }else if(!this.selectedprogramlist.length){
         this.showSnackbar("red","please select Program");
      }else if(!this.selectedyearlist.length){
         this.showSnackbar("red","please select Year");
      }else{
        var params = {
          organization: this.selectedorg,
          programlist: this.selectedprogramlist,
          yearlist : this.selectedyearlist,
          companyoffering: this.scheduling_id,
        };
        //console.log(params);
        axios
        .post("/TPOCompanyScheduling/savecompanyofferingprogram", params)
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.dialog = false;
            this.showSnackbar("green", "save successfully....");
            this.getofferinginfo();
            this.selectedprogramlist=[];
            this.selectedyearlist=[];
            this.selectedorg="";
            this.selectedprgtype="";
           // this.getprogramlistforadd();
          } else {}
        });
      }
    },

    getprogramlist() {
      axios.post("/TPOCompanyScheduling/getCompanyOfferingList").then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.items = res.data.companylist;
          this.nameidmap = res.data.nameidmap;
        } else {}
      });
    },

    opendialog() {
      this.selectedorg = "";
      this.selectedprogramlist = [];
      this.selectedyearlist = []
      this.dialog = true;
    },
    getprogramlistforadd() {
      this.program_list=[]
      this.year_list = []
      var params = {
        organization: this.selectedorg,
        companyoffering: this.scheduling_id,
      };

      axios.post("/TPOCompanyScheduling/getprogramlist", params).then((res) => {
        //console.log(res.data);
        if (res.data.msg == "200") {
          this.program_list = res.data.programlist;
          this.year_list = res.data.yearlist;
        } else {}
      });
    },

    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },

    get_company_list() {
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingList")
        .then((res) => {
          //console.log(res.data);
          if (res.data.msg == "200") {
            this.items = res.data.companylist;
            //console.log("this.items");
            //console.log(this.items);
            this.nameidmap = res.data.nameidmap;
          } else {}
        })
        .catch((error) => {
          window.console.log(error);
        });
    },
    getofferinginfo() {
      const data = {
        offering: this.scheduling_id,
      };

      //console.log(data);
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingProgram", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.programdata = res.data.data;
            this.orglist = res.data.orglist;
            this.url1 = res.data.url1;
            //console.log(this.programdata);
          } else {
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    getofferinginfobyid() {
      const data = {
        offering: this.scheduling_id,
      };
      axios
        .post("/TPOCompanyScheduling/getCompanyOfferingOrganization", data)
        .then((res) => {
          console.table(res.data);
          if (res.data.msg == "200") {
            this.orglist = res.data.orglist;
          } else {
            window.console.log(error);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
    },

    onLoad() {
     
      this.scheduling_id = this.$route.params.scheduling_id;
        if(this.scheduling_id==undefined)
   {
     this.scheduling_id=localStorage.getItem("scheduling_id")
   }
      this.selected_company = this.$route.params.cmp_name;
        if(this.selected_company==undefined)
   {
     this.selected_company=localStorage.getItem("companyname")
   }
this.code = this.$route.params.code;
     if(this.code==undefined)
   {
     this.code=localStorage.getItem("code")
   }
this.academicyear = this.$route.params.ay;
     if(this.academicyear==undefined)
   {
     this.academicyear=localStorage.getItem("ay")
   }
      this.getofferinginfo();
   
    },
  },
  mounted() {
    this.onLoad();
  },
};
</script>
<style scoped>
.ec-title {
  background-image: linear-gradient(-100deg, #f4faba, #d64907);
  color: #fff;
}
.c-title {
  background-image: linear-gradient(-90deg, #befcf7, #03948d);
  color: #fff;
}
.tc-title {
  background-image: linear-gradient(-90deg, #fff, #057996);
  color: #fff;
  border-radius: 3px;
}
.btn-title {
  background-image: linear-gradient(-100deg, #8aa5f7, #063bdb);
  color: #fff;
}
.card-title {
  background-image: linear-gradient(-90deg, #8edffd, #034c70);
  color: #fff;
}
.lbl {
  color: #1705e6;
}
.c-title1 {
  background-image: linear-gradient(-90deg, #dbdbdb, #012944);
  color: #fff;
}
.link-icon {
  color: #fff;
}
.btn-title2 {
  background: linear-gradient(-90deg, #30cfd0 0%, #330867 100%);
}
</style>